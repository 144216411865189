const contract = {
  state: {
    contractId: 0,
    contractSetting: false,
    statementSetting: false,
    beginUpdate: new Date()
  },
  mutations: {
    set_contract_contractId (state, data) {
      state.contractId = data
    },
    set_contract_update (state, data) {
      state.beginUpdate = data
    },
    set_contract_contractSetting (state, data) {
      state.contractSetting = data
    },
    set_contract_statementSetting (state, data) {
      state.statementSetting = data
    }
  },
  getters: {

  },
  actions: {

  }
}

export default contract
