<template>
  <div>
    <container>
      <div slot="left_box_content">
        <component :is="curLeftComponent" @on-change="handleChangePageType" @on-create="handleShowCreateOrEdit"></component>
      </div>
      <div slot="right_box_content">
        <component :is="curRightComponent" ></component>
      </div>
      <div slot="bottom_box_content">
        <component :is="curBottomComponent" :baseId="baseId" :otherParams="otherParams"
          @on-detail="handleShowDetail" @on-edit="handleShowCreateOrEdit" @on-submit="handelSubmit"></component>
      </div>
    </container>
  </div>
</template>

<script>
import Container from '@/components/common/Container'
import { sysMixins } from '@/assets/mixins/sys'
// 引入store模块
import contractStoreModule from '@/store/modules/contract'

export default {
  mixins: [sysMixins],
  components: {
    Container,
    salerOverviewComponent: () => import('@/components/overview/SalerOverview'),
    leftComponent: () => import('./Left'),
    // 合同
    contractList: () => import('@/components/contract/List'),
    contractEdit: () => import('@/components/contract/Edit'),
    contractDetail: () => import('@/components/contract/Detail'),
    // 合同变更
    contractChangeList: () => import('@/components/contractChange/List'),
    contractChangeEdit: () => import('@/components/contractChange/Edit'),
    contractChangeDetail: () => import('@/components/contractChange/Detail'),

    // 合同开票
    invoiceList: () => import('@/components/invoice/List'),
    invoiceDetail: () => import('@/components/invoice/Detail'),
    // 确认单
    agreementList: () => import('@/components/agreement/List'),
    agreementEdit: () => import('@/components/agreement/Edit'),
    agreementDetail: () => import('@/components/agreement/Detail'),

    // 结算单
    statementList: () => import('@/components/statement/List'),
    statementEdit: () => import('@/components/statement/Edit'),
    statementDetail: () => import('@/components/statement/Detail'),

    // 结算单变更
    statementChangeList: () => import('@/components/statementChange/List'),
    statementChangeEdit: () => import('@/components/statementChange/Edit'),
    statementChangeDetail: () => import('@/components/statementChange/Detail'),
    // 结算单开票
    statementInvoiceList: () => import('@/components/statementInvoice/List'),
    statementInvoiceDetail: () => import('@/components/statementInvoice/Detail')

  },
  created () {
    // 注册动态store模块
    if (!this.$store.hasModule('contract')) {
      this.$store.registerModule('contract', contractStoreModule)
    }
  },
  data () {
    return {
      closePersonInfo: this.$store.getters.token.publisherSetting.personInfo, // 关闭个人能力
      baseId: 0,
      otherParams: 0,
      pageType: '',
      pageName: ''
    }
  },
  mounted () {
    this.setLeftComponent('leftComponent')
    if (this.closePersonInfo) {
      this.setRightComponent('')
      this.setShowRightBlock(false)
    } else {
      this.setRightComponent('salerOverviewComponent')
    }

    this.setBottomHeight('90%')

    this.handleChangePageType('contract', '合同')
  },
  methods: {
    handleChangePageType (pageType, pageTagName) {
      this.pageName = pageTagName
      this.pageType = pageType
      this.setBottomComponent(pageType + 'List')
      this.initTagEvent('list')
    },
    handleShowDetail (baseId) {
      this.baseId = baseId
      this.setBottomComponent(this.pageType + 'Detail')
      this.initTagEvent('detail')
    },
    handleShowCreateOrEdit (baseId) {
      if (baseId && baseId.versionId) {
        this.baseId = baseId.id
        this.otherParams = baseId.versionId
      } else {
        this.baseId = baseId
      }

      this.setBottomComponent(this.pageType + 'Edit')
      this.initTagEvent('edit')
    },
    handelSubmit () {
      this.setBottomComponent(this.pageType + 'List')
      this.initTagEvent('list')
    },
    initTagEvent (tagType) {
      // 清空全部清单
      this.setLinkTagArray()

      const listTagName = this.pageType + 'List'
      const detailTagName = this.pageType + 'Detail'
      const editTagName = this.pageType + 'Edit'

      this.setLinkTagArray({ key: listTagName, value: this.pageName + '清单' })
      this.setLinkRouterMaps([listTagName, () => {
        this.setBottomComponent(this.pageType + 'List')
      }])

      // 注册头部事件
      if (tagType === 'list') {
        this.setLinkRouterMaps([listTagName, () => {}])
        this.setActivedTagName(listTagName)
      } else if (tagType === 'detail') {
        this.setLinkTagArray({ key: detailTagName, value: this.pageName + '详情' })
        this.setActivedTagName(detailTagName)
        this.setLinkRouterMaps([detailTagName, () => { }])
      } else if (tagType === 'edit') {
        this.setLinkTagArray({ key: editTagName, value: '配置' + this.pageName })
        this.setLinkRouterMaps([editTagName, () => { }])
        this.setActivedTagName(editTagName)
      }
    }
  },
  destroyed () {
    // 卸载动态store模块
    this.$store.unregisterModule('contract')
    this.setShowLeftBlock(true)
    this.setShowMap(true)
    this.setShowRightBlock(true)
    this.setBottomHeight('300px')
    this.setLeftComponent('')
    this.setRightComponent('')
    this.setBottomComponent('')
    this.setMapComponent('')
    this.setLinkTagArray()
    this.setLinkRouterMaps()
    this.setActivedTagName('')
  }
}
</script>
